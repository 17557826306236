import { displayErrorToast } from '~/features/toast';
import { ac } from '../../../utils/redux-utils';
import { legacyApi } from '@mspecs/shared-utils';
import {
    notificationUpdateSuccess,
    notificationUpdateError,
} from '~/utils/notification-utils';
import { SIGN_METHODS } from '~/components/modal/esign-modal/esign-constants';

export const ESIGNATURE_ACTION_TYPES = {
    SEND_SUCCESS: 'SEND_SUCCESS',
    GET_RECEIVERS_SUCCESS: 'GET_RECEIVERS_SUCCESS',
    ADD_RECEIVER_SUCCESS: 'ADD_RECEIVER_SUCCESS',
    EDIT_RECEIVER_SUCCESS: 'EDIT_RECEIVER_SUCCESS',
    CLOSE_RECEIVER_SUCCESS: 'CLOSE_RECEIVER_SUCCESS',
    EDIT_RECEIVER_STARTING: 'EDIT_RECEIVER_STARTING',
    ADD_RECEIVER_STARTING: 'ADD_RECEIVER_STARTING',
    UPDATE_RECEIVER: 'UPDATE_RECEIVER',
    SENDING_FOR_ESIGNING_STARTING: 'SENDING_FOR_ESIGNING_STARTING',
    SENDING_FOR_ESIGNING_ERROR: 'SENDING_FOR_ESIGNING_ERROR',
    UPDATE_SENDING_DATA: 'UPDATE_SENDING_DATA',
    GET_LANGUAGES_SUCCESS: 'GET_LANGUAGES_SUCCESS',
    REMOVE_DOCUMENT_ATTACHMENT: 'REMOVE_DOCUMENT_ATTACHMENT',
    MODIFY_SELCTED_DEAL_FILES: 'MODIFY_SELCTED_DEAL_FILES',
    UPDATE_DOCUMENT_ATTACHMENTS: 'UPDATE_DOCUMENT_ATTACHMENTS',
    GET_RECIPIENTS_STATUS_SUCCESS: 'GET_RECIPIENTS_STATUS_SUCCESS',
    PREPARE_RECIPIENTS_SUCCESS: 'PREPARE_RECIPIENTS_SUCCESS',
    IS_START_LOADING_SUCCESS: 'IS_START_LOADING_SUCCESS',
};

export const modifySelectedDealFiles = data =>
    ac(ESIGNATURE_ACTION_TYPES.MODIFY_SELCTED_DEAL_FILES, data);

const successSendEsignature = data =>
    ac(ESIGNATURE_ACTION_TYPES.SEND_SUCCESS, {
        data,
    });
const successGetReceivers = receivers =>
    ac(ESIGNATURE_ACTION_TYPES.GET_RECEIVERS_SUCCESS, receivers);

export const successEditReceiver = receiver =>
    ac(ESIGNATURE_ACTION_TYPES.EDIT_RECEIVER_SUCCESS, {
        receiver,
    });

export const successCloseReceiver = receiverObject =>
    ac(ESIGNATURE_ACTION_TYPES.CLOSE_RECEIVER_SUCCESS, receiverObject);

export const successAddReceiver = receiver =>
    ac(ESIGNATURE_ACTION_TYPES.EDIT_RECEIVER_SUCCESS, {
        receiver,
    });

export const editReceiverStarting = receiver =>
    ac(ESIGNATURE_ACTION_TYPES.EDIT_RECEIVER_STARTING, receiver);

export const addReceiverStarting = () =>
    ac(ESIGNATURE_ACTION_TYPES.ADD_RECEIVER_STARTING);

export const updateReceiver = receiver =>
    ac(ESIGNATURE_ACTION_TYPES.UPDATE_RECEIVER, {
        receiver,
    });

const startSendingEsign = () =>
    ac(ESIGNATURE_ACTION_TYPES.SENDING_FOR_ESIGNING_STARTING);

const sendingEsigningError = error =>
    ac(ESIGNATURE_ACTION_TYPES.SENDING_FOR_ESIGNING_ERROR, {
        error,
    });

export const updateSendingEsinData = sendData =>
    ac(ESIGNATURE_ACTION_TYPES.UPDATE_SENDING_DATA, sendData);

const getLanguagesSuccess = languages =>
    ac(ESIGNATURE_ACTION_TYPES.GET_LANGUAGES_SUCCESS, languages);

export const updateDocumentAndAttachments = documents =>
    ac(ESIGNATURE_ACTION_TYPES.UPDATE_DOCUMENT_ATTACHMENTS, documents);

export const removeAttachment = document =>
    ac(ESIGNATURE_ACTION_TYPES.REMOVE_DOCUMENT_ATTACHMENT, document);

const getRecipientsStatusSuccess = recipients =>
    ac(ESIGNATURE_ACTION_TYPES.GET_RECIPIENTS_STATUS_SUCCESS, recipients);

const prepareRecipientsSuccess = object =>
    ac(ESIGNATURE_ACTION_TYPES.PREPARE_RECIPIENTS_SUCCESS, object);

const startLoading = () => ac(ESIGNATURE_ACTION_TYPES.IS_START_LOADING_SUCCESS);

async function getBrokers(dealId) {
    return legacyApi(`/teamRoles`)
        .query({
            q: `dealId = '${dealId}'`,
        })
        .get();
}

function getDocumentTypeObject(document) {
    switch (document.type) {
        case 'Document':
            return { data: { versionId: document.id } };
        case 'Link':
            return { data: { url: document.url } };
        case 'File':
            return { data: { fileId: document.id } };
        case 'BinaryFile':
            return {
                data: { binaryFileUuId: document.id },
                binaryFile: document.formData,
            };
        case 'StaticDocument':
            return {
                data: {
                    dealId: document.id,
                    staticDocument: document.staticDocument,
                    url: document.url,
                },
            };
        case 'marketValuation':
            const { id, imageFileIds, pdfFileIds } = document;
            return {
                data: { marketValuationId: id, imageFileIds, pdfFileIds },
            };
        default:
            return { data: {} };
    }
}

function prepareSignData(documentAttachments, document) {
    const binaryData = [];
    const preparedAttachment = (documentAttachments[document.id] || []).map(
        x => {
            x.type = x.type || 'File';
            const object = getDocumentTypeObject(x);
            if (object.binaryFile) {
                binaryData.push({ id: x.id, data: object.binaryFile });
            }
            return object.data;
        }
    );

    return {
        binaryData,
        signTarget: {
            attachments: preparedAttachment,
            ...getDocumentTypeObject(document).data,
        },
    };
}

function displayEsignError(error, dispatch) {
    if (error && error.message) {
        displayErrorToast(error.message);
        dispatch(sendingEsigningError());
    } else {
        dispatch(sendingEsigningError());
        notificationUpdateError();
    }
}

export function signingDocument(
    message,
    dueDate,
    documentId,
    signers,
    reviewers,
    dealId,
    documentType,
    documents,
    documentAttachments
) {
    return async dispatch => {
        const signingData = {
            dueDate,
            message,
            signers,
            reviewers,
            dealId,
        };

        let binaryData = [];
        const signTargets = [];
        let documentApi;
        documents.forEach(document => {
            const signData = prepareSignData(documentAttachments, document);
            signTargets.push(signData.signTarget);
            binaryData = [...binaryData, ...signData.binaryData];
        });

        if (documentType === 'ENUM_ESIGNATURE_DOCUMENT_BUNDLE') {
            signingData.signTargets = signTargets;
            documentApi = '/v3/document/sign/bundle';
            signingData.dealDocumentBundleId = documentId;
        } else {
            signingData.signTarget = signTargets[0];
            documentApi = '/v3/document/sign/item';
        }

        try {
            dispatch(startSendingEsign());
            const formData = new FormData();
            binaryData.forEach(file => {
                formData.append(file.id, file.data);
            });
            formData.append('jsonData', JSON.stringify(signingData));

            const sendData = await legacyApi(documentApi).postAsForm(formData);

            dispatch(successSendEsignature());
            notificationUpdateSuccess();
            return sendData;
        } catch (error) {
            displayEsignError(error, dispatch);
            return null;
        }
    };
}

export function getLanguages() {
    return async dispatch => {
        const languages = await legacyApi(`/languages`).get();
        dispatch(getLanguagesSuccess(languages));
    };
}

function prepareReceiver(contacts, receivers, role) {
    const preparedReceivers = [];
    receivers.forEach(receiver => {
        const receiverContact = contacts.find(x => x.id === receiver.contactId);
        if (!receiverContact) {
            return;
        }
        const signProperties = {
            isSign: true,
            isApprove: false,
            secure: true,
            role: role,
            signMethod: SIGN_METHODS.BANKID_SE,
        };
        if (receiverContact.isLegalEntity) {
            receiverContact.employees.forEach(employee => {
                const companyDisplayName = `${receiverContact.companyName}`;
                const displayName = `${receiverContact.companyName} - ${employee.firstName} ${employee.lastName}`;

                preparedReceivers.push({
                    ...employee,
                    companyDisplayName,
                    displayName,
                    ...signProperties,
                });
            });
        } else {
            receiverContact.displayName = `${receiverContact.firstName} ${receiverContact.lastName}`;
            preparedReceivers.push({ ...receiverContact, ...signProperties });
        }
    });
    return preparedReceivers;
}

async function getSellers(dealId) {
    return legacyApi(`/sellers`)
        .query({
            q: `dealId = '${dealId}'`,
        })
        .get();
}

async function getBuyers(dealId) {
    const deal = await legacyApi(`/deals/${dealId}`).get();
    if (deal.buyerGroupId) {
        return legacyApi(`/buyers`)
            .query({
                q: `buyerGroupId = '${deal.buyerGroupId}'`,
            })
            .get();
    } else {
        return [];
    }
}

export function getReceivers(dealId) {
    return async dispatch => {
        const receiverData = {
            sellers: [],
            buyers: [],
            brokers: [],
        };

        const sellers = await getSellers(dealId);
        const buyers = await getBuyers(dealId);
        const brokers = await getBrokers(dealId);

        const contactIds = [
            ...sellers.map(x => x.contactId),
            ...buyers.map(x => x.contactId),
            ...brokers.map(x => x.contactId),
        ];

        const contacts = await getContacts(contactIds);
        receiverData.sellers = prepareReceiver(contacts, sellers, 'SELLER');
        receiverData.buyers = prepareReceiver(contacts, buyers, 'BUYER');
        receiverData.brokers = prepareReceiver(contacts, brokers, 'BROKER');
        dispatch(successGetReceivers(receiverData));
    };
}

async function getContacts(contactIds) {
    const contacts = await legacyApi(`/contacts`)
        .query({
            q: `id in ('${contactIds.join(',')}')`,
        })
        .get();

    const companies = contacts.filter(contact => contact.isLegalEntity);

    if (companies.length) {
        const companyContactIds = companies.map(x => x.id);
        const companyContacts = await legacyApi(`/companyContacts`)
            .query({
                q: `companyContactId in ('${companyContactIds.join(',')}')`,
            })
            .get();

        const employeeContactIds = [
            ...companyContacts.map(x => x.employeeContactId),
        ];

        let employeeContacts = [];
        if (employeeContactIds.length) {
            employeeContacts = await legacyApi(`/contacts`)
                .query({
                    q: `id in ('${employeeContactIds.join(
                        ','
                    )}') AND isExternalContact=true`,
                })
                .get();
        }

        companies.map(company => {
            const companyConts = companyContacts.filter(
                x => x.companyContactId === company.id
            );
            if (companyConts.length) {
                company.employees = employeeContacts.filter(y =>
                    companyConts.find(x => y.id === x.employeeContactId)
                );
            } else {
                company.employees = [];
            }
            return company;
        });

        return [...contacts, ...companies];
    }
    return contacts;
}

export function updateDocumentAttachments(documentAttachments) {
    return dispatch => {
        dispatch(updateDocumentAndAttachments(documentAttachments));
    };
}

export function getRecipientsStatus(envelopeUid) {
    return async dispatch => {
        const recipients = await legacyApi(
            `/v3/document/sign/recipient/status`
        ).post({
            envelopeUid,
        });
        dispatch(getRecipientsStatusSuccess(recipients));
    };
}

async function getRecipients(items) {
    return Promise.all(
        items.map(async item => {
            const recipients = await legacyApi(
                `/v3/document/sign/recipient/status`
            ).post({
                envelopeUid: item.meta.envelopeUid,
            });
            return { ...item, recipients };
        })
    );
}

export function prepareDocumentsRecipients(eSignObject) {
    return async dispatch => {
        dispatch(prepareRecipientsSuccess({}));
        dispatch(startLoading());
        const items = eSignObject.items
            ? await getRecipients(eSignObject.items)
            : [];
        eSignObject.items = items;
        dispatch(prepareRecipientsSuccess(eSignObject));
    };
}

export function getEsignDocument(digitalSignatureId) {
    return async dispatch => {
        dispatch(prepareRecipientsSuccess({}));
        dispatch(startLoading());
        const eSignObject = digitalSignatureId
            ? await legacyApi(
                  `/v3/document/sign/item/${digitalSignatureId}/status`
              ).get()
            : {};
        dispatch(prepareRecipientsSuccess(eSignObject));
    };
}

export function cancelEsigning(signTarget, isNoNotification) {
    return async dispatch => {
        try {
            await legacyApi(`/v3/document/sign/item/abort`).put(signTarget);
            !isNoNotification && notificationUpdateSuccess();
            return true;
        } catch (error) {
            displayEsignError(error, dispatch);
            return false;
        }
    };
}
