import {
    errorColor,
    textSecondary,
    textPrimary,
    borderPrimary,
    borderSecondary,
    primaryColor60,
    primaryColor15,
    primaryColor10,
    primaryColor5,
    gray120,
    green40,
    green15,
    bgSecondary,
} from '@mspecs/shared-utils';

export const getSelectStyles = styles => {
    return {
        menuPortal: provided => ({
            ...provided,
            zIndex: '10000000000',
        }),
        container: (provided, { isDisabled, isFocused, selectProps }) => {
            return {
                ...provided,
                backgroundColor: '#fff',
                border: 'none',
                padding: '0',
                cursor: 'pointer',
                width: '100%',
                color: isDisabled && textSecondary,
                borderRadius: '4px',
                boxShadow: selectProps.isSaved
                    ? `0 0 0 2px ${green15}`
                    : isFocused
                    ? `0 0 0 2px ${primaryColor15}`
                    : 'none',
            };
        },
        control: (provided, props) => ({
            ...provided,
            cursor: 'pointer',
            boxShadow: 'none',
            minHeight: 'initial',
            height: '34px',
            borderColor: props.selectProps.invalid
                ? errorColor
                : props.isDisabled
                ? borderSecondary
                : props.selectProps.isSaved
                ? green40
                : props.isFocused
                ? primaryColor60
                : borderPrimary,
            width: '100%',
            backgroundColor: props.isDisabled && bgSecondary,
            '&:hover': {
                borderColor: props.isFocused ? primaryColor60 : gray120,
            },
        }),
        placeholder: provided => ({
            ...provided,
            color: textSecondary,
        }),
        valueContainer: provided => ({
            ...provided,
            whiteSpace: 'nowrap',
            flexWrap: 'initial',
            padding: '0 0 0 8px',
        }),
        indicatorsContainer: provided => ({
            ...provided,
            padding: '0',
            height: '100%',
            color: textPrimary,
        }),
        indicatorContainer: provided => ({
            ...provided,
            alignItems: 'center',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        menu: provided => ({
            ...provided,
            marginTop: '10px',
            paddingTop: '0',
            maxWidth: '340px',
            zIndex: 9999,
            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
        }),
        menuList: provided => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        group: provided => ({
            ...provided,
            borderTop: '1px solid #e9ecef',
            paddingTop: 0,
            paddingBottom: 0,
        }),
        groupHeading: provided => ({
            ...provided,
            marginTop: '10px',
            color: '#000',
            fontSize: '13px',
        }),
        multiValue: provided => ({
            ...provided,
            backgroundColor: 'transparent',
        }),
        multiValueLabel: provided => ({
            ...provided,
            lineHeight: '21px',
            fontSize: '14px',
            paddingLeft: '0',
            color: { textSecondary },
            '@media only screen and (max-width: 600px)': {
                fontSize: '16px',
            },
        }),
        multiValueRemove: () => ({
            display: 'none',
        }),
        option: (provided, { isFocused, isSelected, value }) => ({
            ...provided,
            padding: '6.5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
            color: value !== null ? textPrimary : textSecondary,
            fontFamily: 'Inter',
            fontSize: '14px',
            lineHeight: '21px',
            cursor: 'pointer',
            backgroundColor:
                (isFocused && !isSelected && primaryColor10) ||
                (isSelected &&
                    `var(--mspx-select-background--selected, ${primaryColor5})`),
            '&:active': {
                backgroundColor: `var(--mspx-select-background--selected, ${primaryColor10})`,
                color: '#000',
            },
            '&:last-of-type': {
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
            },
            '&:first-of-type': {
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
            },
            '@media only screen and (max-width: 600px)': {
                fontSize: '16px',
            },
        }),
        ...styles,
    };
};
