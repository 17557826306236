import { ac } from '../../utils/redux-utils';
import { legacyApi } from '@mspecs/shared-utils';
import { displaySuccessToast, displayErrorToast } from '~/features/toast';
import { pdfUrlSelector } from '~/redux/selectors/object-description-selector';
import omit from 'lodash-es/omit';

export const ACTION_TYPES = {
    UPDATE_OBJECT_DESCRIPTION_SIGNATURES:
        'UPDATE_OBJECT_DESCRIPTION_SIGNATURES',
    UPDATE_OBJECT_DESCRIPTION_PRINT_OPTIONS:
        'UPDATE_OBJECT_DESCRIPTION_PRINT_OPTIONS',
    GET_OBJECT_DESCRIPTION_OPTIONS: 'GET_OBJECT_DESCRIPTION_OPTIONS',
    UPDATE_OBJECT_DESCRIPTION_OPTIONS: 'UPDATE_OBJECT_DESCRIPTION_OPTIONS',
    SET_ESIGN_STATUS: 'SET_ESIGN_STATUS',
    SET_EDIT_CONFIRMATION: 'SET_EDIT_CONFIRMATION',
};

const updateObjectDescriptionOptions = (id, options) =>
    ac(ACTION_TYPES.UPDATE_OBJECT_DESCRIPTION_OPTIONS, {
        options,
        id,
    });

export const updateObjectDescriptionPrintOptions = options =>
    ac(ACTION_TYPES.UPDATE_OBJECT_DESCRIPTION_PRINT_OPTIONS, {
        options,
    });

export const updateSignatureOptions = signatureOptions =>
    ac(ACTION_TYPES.UPDATE_OBJECT_DESCRIPTION_SIGNATURES, signatureOptions);

export function saveObjectDescriptionPdf(url) {
    return async () => {
        try {
            const file = await legacyApi(url).get();
            displaySuccessToast({
                title: 'FILE',
                body: 'SUCCESSFULLY_ADDED',
            });
            return file;
        } catch {
            displayErrorToast({
                title: 'FILE',
                body: 'DATA_SAVING_ERROR',
            });
        }
    };
}

const getOptions = (id, options) =>
    ac(ACTION_TYPES.GET_OBJECT_DESCRIPTION_OPTIONS, { id, options });

const setEsignStatus = esign => ac(ACTION_TYPES.SET_ESIGN_STATUS, esign);

export function saveOrUpdateObjectDescriptionOptions(dealId, id, options) {
    return async dispatch => {
        try {
            const { printOptions, ...setting } = options;
            setting.printOptions = omit(printOptions, ['appendixes']);

            if (id) {
                await legacyApi(`/dealSettings/${id}`).put({
                    setting,
                });
                dispatch(updateObjectDescriptionOptions(id, options));
            } else {
                const dealSettings = await legacyApi('/dealSettings').post({
                    dealId: dealId,
                    setting,
                    type: 'ENUM_DEAL_SETTING_OBJECT_DESCRIPTION',
                });
                dispatch(
                    updateObjectDescriptionOptions(dealSettings.id, options)
                );
            }
        } catch {
            displayErrorToast('DATA_SAVING_ERROR');
        }
    };
}

export function getObjectDescriptionOptions(dealId, defaultPrintWithQRCode) {
    return async dispatch => {
        try {
            const dealSettings = await legacyApi('/dealSettings')
                .query({
                    q: `dealId='${dealId}' AND type='ENUM_DEAL_SETTING_OBJECT_DESCRIPTION'`,
                })
                .get();
            if (dealSettings.length) {
                const [dealSetting] = dealSettings;
                const { id, setting } = dealSetting;
                // this is for fixing old records which are alrady in the DB
                const { printOptions } = setting;
                setting.printOptions.signature =
                    !printOptions.signature || printOptions.signature === 'NO'
                        ? 'NONE'
                        : printOptions.signature;

                dispatch(getOptions(id, setting));
            } else {
                const printOptions = {
                    addQrScanCode: defaultPrintWithQRCode || false,
                };
                dispatch(getOptions(null, { printOptions }));
            }
        } catch {
            displayErrorToast('DATA_SAVING_ERROR');
        }
    };
}

export function getPdfUrl(props) {
    return (dispatch, getState) => {
        return pdfUrlSelector(getState(), props);
    };
}

export function getEsignStatus(dealId) {
    return async dispatch => {
        try {
            const esign = await legacyApi(
                '/v3/document/sign/item/' +
                    dealId +
                    '/ENUMS_STATIC_DEAL_DOCUMENT_OBJECT_DESCRIPTION/status'
            ).get();
            dispatch(setEsignStatus(esign));
        } catch {
            dispatch(setEsignStatus({}));
        }
    };
}

export function setEditConfirmation(isEditConfirmation) {
    return dispatch => {
        dispatch(
            ac(ACTION_TYPES.SET_EDIT_CONFIRMATION, { isEditConfirmation })
        );
    };
}
