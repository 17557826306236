import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const Link = styled.a`
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 3px;

    :hover {
        text-decoration-thickness: 1.5px;
    }
`;

function MspxMarkdownEditor({ children, ...props }) {
    return (
        <ReactMarkdown
            {...props}
            components={{
                a(props) {
                    return <Link {...props} target="_blank" />;
                },
            }}
        >
            {children}
        </ReactMarkdown>
    );
}
MspxMarkdownEditor.propTypes = {
    children: PropTypes.node,
};

export default MspxMarkdownEditor;
