import { ac } from '../../utils/redux-utils';
import {
    cleanReceivedEmail,
    cleanReceivedEmails,
} from '../../utils/email-utils';
import EmailService from '../../utils/services/email-service';
import { legacyApi } from '@mspecs/shared-utils';
import { displaySuccessToast } from '~/features/toast';

export const OPEN_EMAIL_MODAL = 'OPEN_EMAIL_MODAL';
export const CLOSE_EMAIL_MODAL = 'CLOSE_EMAIL_MODAL';
export const FETCH_EMAILS_REQUEST = 'FETCH_EMAILS_REQUEST';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS';
export const FETCH_EMAIL_SUCCESS = 'FETCH_EMAIL_SUCCESS';
export const EDIT_EMAIL_REQUEST = 'EDIT_EMAIL_REQUEST';
export const NEW_EMAIL_REQUEST = 'NEW_EMAIL_REQUEST';
export const SAVE_EMAIL_REQUEST = 'SAVE_EMAIL_REQUEST';
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const DELETE_EMAIL_SUCCESS = 'DELETE_EMAIL_SUCCESS';
export const SEARCH_CONTACTS_SUCCESS = 'SEARCH_CONTACTS_SUCCESS';
export const ADD_EMAIL_CONTACT = 'ADD_EMAIL_CONTACT';
export const REMOVE_EMAIL_CONTACT = 'REMOVE_EMAIL_CONTACT';
export const POST_EMAIL_ATTACHMENT_SUCCESS = 'POST_EMAIL_ATTACHMENT_SUCCESS';
export const DELETE_EMAIL_ATTACHMENT_SUCCESS =
    'DELETE_EMAIL_ATTACHMENT_SUCCESS';

function getUserId(getState) {
    const state = getState();
    if (state && state.session) {
        return state.session.id;
    }
    return null;
}

export function openModal() {
    return ac(OPEN_EMAIL_MODAL);
}

export function closeModal() {
    return ac(CLOSE_EMAIL_MODAL);
}

function requestFetchEmails() {
    return ac(FETCH_EMAILS_REQUEST);
}

function successFetchEmails(emails) {
    return ac(FETCH_EMAILS_SUCCESS, emails);
}

export function fetchEmails(perPage, offset) {
    return (dispatch, getState) => {
        dispatch(requestFetchEmails());
        return EmailService.getAllEmails(
            getUserId(getState),
            perPage,
            offset
        ).then(emails => {
            dispatch(successFetchEmails(cleanReceivedEmails(emails)));
        });
    };
}

function requestEditEmail(id) {
    return ac(EDIT_EMAIL_REQUEST, id);
}

function successFetchEmail(email) {
    return ac(FETCH_EMAIL_SUCCESS, email);
}

function decorateEmail(email, userId) {
    const decoratedEmail = email;

    const promises = [];
    promises.push(
        EmailService.getContacts(email.id).then(contacts => {
            decoratedEmail.recipients = contacts;
        })
    );

    promises.push(
        EmailService.getAttachments(email.id).then(files => {
            decoratedEmail.attachments = files;
        })
    );

    promises.push(
        EmailService.getEmailSignature(userId).then(signature => {
            decoratedEmail.body = `<br><br><br>${signature}`;
        })
    );

    return Promise.all(promises).then(() => decoratedEmail);
}

export function editEmail(id) {
    return (dispatch, getState) => {
        dispatch(requestEditEmail(id));
        const userId = getUserId(getState);
        return EmailService.getEmail(id).then(email =>
            decorateEmail(email, userId).then(decoratedEmail =>
                dispatch(successFetchEmail(decoratedEmail))
            )
        );
        // TODO: better error handling. Currently, if a
        // Communication is linked to an invalid emailId
        // it just crashes here.
    };
}

export function newEmail() {
    return ac(NEW_EMAIL_REQUEST);
}

function requestSaveEmail() {
    return ac(SAVE_EMAIL_REQUEST);
}

function successCreateEmail(email) {
    return ac(CREATE_EMAIL_SUCCESS, email);
}

function successUpdateEmail(email) {
    return ac(UPDATE_EMAIL_SUCCESS, email);
}

export function saveEmail(data) {
    // Perform different actions depending on create or update.
    const callback = data.id ? successUpdateEmail : successCreateEmail;

    return (dispatch, getState) => {
        dispatch(requestSaveEmail());
        const userId = getUserId(getState);
        return EmailService.saveEmail(userId, data).then(email =>
            decorateEmail(cleanReceivedEmail(email), userId).then(
                decoratedEmail => dispatch(callback(decoratedEmail))
            )
        );
    };
}

function requestSendEmail() {
    return ac(SEND_EMAIL_REQUEST);
}

function successSendEmail(email) {
    return ac(SEND_EMAIL_SUCCESS, email);
}

export function sendEmail(data) {
    return dispatch => {
        dispatch(requestSendEmail());
        return EmailService.sendEmail(data).then(sentEmail => {
            dispatch(successUpdateEmail(cleanReceivedEmail(sentEmail)));
            dispatch(successSendEmail(sentEmail));
            displaySuccessToast('EMAIL_SENT_SUCCESSFULLY');
            return sentEmail;
        });
    };
}

function successDeleteEmail(id) {
    return ac(DELETE_EMAIL_SUCCESS, id);
}

export function deleteEmail(id) {
    return dispatch =>
        EmailService.deleteEmail(id).then(() =>
            dispatch(successDeleteEmail(id))
        );
}

export function deleteEmails(ids) {
    return dispatch => {
        ids.forEach(id => {
            dispatch(deleteEmail(id));
        });
    };
}

function successSearchContacts(contacts) {
    return ac(SEARCH_CONTACTS_SUCCESS, contacts);
}

export function searchContacts() {
    return dispatch => dispatch(successSearchContacts([]));

    // return dispatch => EmailService.searchContacts(getUserId(), q).then(contacts =>
    //     dispatch(successSearchContacts(contacts)));
}

export function addContact(contact) {
    return ac(ADD_EMAIL_CONTACT, contact);
}

export function removeContact(contact) {
    return ac(REMOVE_EMAIL_CONTACT, contact);
}

function successPostEmailAttachment(data) {
    return ac(POST_EMAIL_ATTACHMENT_SUCCESS, data);
}

function saveEmailAttachment(emailId, file) {
    const api = () => legacyApi('/emailFiles');

    return dispatch =>
        api()
            .post({ emailId, fileId: file.id })
            .then(ef => {
                const f = file;
                f.emailFileId = ef.id;
                dispatch(successPostEmailAttachment(f));
            });
}

export function postEmailAttachments(emailId, files) {
    const api = () => legacyApi('/files');

    return dispatch => {
        const promises = files.map(file => {
            const formData = new FormData();
            formData.append('files', file);
            return api()
                .postAsForm(formData)
                .then(data => {
                    dispatch(saveEmailAttachment(emailId, data));
                });
        });

        return Promise.all(promises);
    };
}

function successDeleteEmailAttachment(id) {
    return ac(DELETE_EMAIL_ATTACHMENT_SUCCESS, id);
}

function deleteFile(emailId, id) {
    const api = () => legacyApi(`/files/${id}`);

    return dispatch =>
        api()
            .delete()
            .then(() => {
                dispatch(successDeleteEmailAttachment(id));
            });
}

export function deleteAttachment(emailId, emailFileId, fileId) {
    const api = () => legacyApi(`/emailFiles/${emailFileId}`);

    return dispatch =>
        api()
            .delete()
            .then(() => {
                dispatch(deleteFile(emailId, fileId));
            });
}
